import React from 'react';
import {
  FaTwitterSquare,
  FaTelegram,
  FaDiscord
} from 'react-icons/fa';

import WhitePaper from '../assets/whitepaper.pdf'
import HesterImage from '../assets/hester_logo.png'

export const Footer = () => {
  return (
    <div id="footer" className='flex max-w-[1240px] mx-auto py-16 px-4 gap-8 text-gray-300'>
      <div className='w-1/2 '>
        <h1 className='w-full text-3xl font-bold text-basicViolet'>
            dickbr
            <span className='text-white'>AI</span>
            n
        </h1>
        <p className='py-4'>
          Whether the market's up or down, $DICK always knows how to stay hard in the game. Invest in laughs, invest in gains!
        </p>
        <div className='flex justify-start md:w-[75%] my-6'>
            <a href="https://twitter.com/DickBrainMeme" target='_blank'>
                <FaTwitterSquare size={30} />
            </a>
            <a href="https://t.me/DickBrainn" target='_blank' className='mx-4'>
                <FaTelegram size={30} />
            </a>
            <a href="https://discord.gg/g35trUyT9J" target='_blank' className=''>
                <FaDiscord size={30} />
            </a>
        </div>
      </div>
      <div className='w-1/2 flex justify-end smt-6'>
        <div>
          <h6 className='font-medium text-gray-400'>Additional resources</h6>
            <ul>
              <li title='coming soon' className='mt-2 py-2 text-sm text-white'>                
                <a href={WhitePaper} target="_blank">White Paper</a>
              </li>
              <li title='coming soon' className='py-2 text-sm text-gray-500'>Litepaper-soon</li>
            </ul>
        </div>
      </div>
    </div>
  );
};