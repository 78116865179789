import React, { useRef, useState, useEffect } from 'react';
import grassImg from '../assets/pumping/grass_cover.png'
import bottomImg from '../assets/pumping/bottom_part.png'
import topImg from '../assets/pumping/top_part.png'
import scaleImg from '../assets/pumping/scale_mc_test.png'
import bgHolderImg from '../assets/pumping/background_holder.png'
import backgroundImg from '../assets/pumping/background.png'
import videoHdSrc from '../assets/pumping/hd_video.mp4'; // Path to your MP4 video
import {TailSpin} from 'react-loader-spinner';
import {getCm} from '../services/gameService'

export const DickSummer = () => {    
    const domainUrl = 'https://dickwifbrain.xyz/dickapi';
    const [marketCap, setMarketCap] = useState(null); // State to hold the marketCap
    const [positionY, setPositionY] = useState(87);  // Starting position is 80%
    const [progressMc, setProgressMc] = useState(0);  // Starting position is 80%
    const [animationStarted, setAnimationStarted] = useState(false);
    const animationRef = useRef(null);
    const formatter = new Intl.NumberFormat('us-US');
    //useEventEmitter('runAction', action => startAnimation());

    const [isLoading, setIsLoading] = useState(true);
   
    useEffect(() => {
      setTimeout(() => setIsLoading(false), 2000); // Simulate loading      
      
      const fetchData = async() => {
        const data = await getCm();
        setMarketCap(data.totalCentimeters)
      }
      fetchData();
    }, []);
  
    const percentOfMoon = (mc) => {
        //stage 1 je pol mansi kukr sm hotu
        //mc = 40000
            mc = 2 * mc;
        console.log(mc);
        if(mc < 110000) {
            mc = mc * 1.085;
        } else if(mc < 210000) {
            mc = mc * 1.04;
        } else if(mc < 310000) {
            mc = mc * 1.02;
        } else if(mc < 410000) {
            mc = mc * 1.01;
        } else if(mc < 510000) {
            mc = mc * 1.005;
        } else if(mc < 610000) {
            mc = mc * 1.000;
        } else if(mc < 710000) {
            mc = mc * 1.000;
        } else if(mc < 810000) {
            mc = mc * 0.995;
        } else if(mc > 910000) {
            mc = mc * 1.045;
        }
        let ret = mc/1050000;
        console.log(ret);
        if(ret < 0.035) {
            ret = 0.035;
        }
        return ret;
    }

    const scrollToPage = () => {

        const footer = document.getElementById('footer');
        setPositionY(87);
        if (footer) {
            footer.scrollIntoView({ behavior: 'smooth' });
            setAnimationStarted(true);
            setTimeout(() => {
                startAnimation();
            }, 1000);
        }
    };

    const startAnimation = () => {    
        const startTime = Date.now();
        const startDick = 87; 
        setPositionY(startDick);

        const navbar = document.getElementById('footer');
        if (navbar) {
            const rect = navbar.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            var navbarStartPosition = 0.80 * (rect.top + scrollTop); 
        }
        var st = document.documentElement.scrollTop;
        const percentMove = percentOfMoon(marketCap)-0.035;
        console.log("level " + percentMove);
        
        const duration = 20000 * percentMove;
        const dickStopPosition = (1 - percentMove) * startDick; 
        const dickDistance =  startDick - dickStopPosition;

        const animate = () => {
            const elapsedTime = Date.now() - startTime;
            const progress = Math.min(elapsedTime / duration, 1); 
            setProgressMc(progress*marketCap);
            window.scrollTo(0, st - st*1.1*((progress * dickDistance)/100.0));            
            setPositionY(startDick - (progress * dickDistance));
            
            if (progress < 1) {
                animationRef.current = requestAnimationFrame(animate);
            } else {
                cancelAnimationFrame(animationRef.current);
                setAnimationStarted(false);
            }
        };

        animationRef.current = requestAnimationFrame(animate);
    };

    return (
            <div id="pumping" className='max-w-[1240px] w-full mx-auto relative overflow-hidden' >
                 { isLoading &&<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
                    </div>
                }
                <img src={backgroundImg} 
                    alt="Sky" 
                    className="absolute max-w-[1240px] w-full mx-auto"         
                />
                { <button 
                    onClick={scrollToPage} 
                    className='bg-basicViolet md:w-[200px] w-[150px] sm:text-sm rounded-md font-medium mx-auto py-3 text-white fixed md:left-[40%] left-[2%] z-10 blink-size'>
                        How big is the dick? <br></br>{formatter.format(Math.floor(progressMc))} cm
                        
                </button> }
           
                <video autoPlay loop muted className="absolute max-w-[1240px] w-full mx-auto bottom-0" >
                    <source src={videoHdSrc} type="video/mp4"  />
                    Your browser does not support the video tag.
                </video> 
                <img src={topImg} alt="Top" className="absolute max-w-[1240px] w-full mx-auto" style={{transform: `translateY(${positionY}%)`}} />
                <img src={bottomImg} alt="Bottom" className="absolute max-w-[1240px] w-full mx-auto"  />
                <img src={grassImg} alt="Grass" className="absolute max-w-[1240px] w-full mx-auto" />
                <img src={scaleImg} alt="Scale" className="absolute max-w-[1240px] w-full mx-auto" />
                <img src={bgHolderImg} alt="Sky" className="max-w-[1240px] w-full mx-auto" />
                
            </div>
    );
}
