import React from 'react';
import { ReactTyped } from "react-typed";
import Dick from '../assets/dick.png';

export const WhatIs = () => {
  return (
    <div id="what-is" className='text-white mb-10 z-10'>
      <div className='max-w-[1200px] w-full mx-auto min-h-screen text-center flex flex-col justify-start p-4'>
        <img className='md:w-[350px] lg:w-[450px] w-[250px] mx-auto my-4' src={Dick} alt='/' />    
        <div className='flex justify-center items-center'>        
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
            Something
          <ReactTyped
          className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-2 pl-1 text-basicViolet'
            strings={['BIG', 'DICK']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
          is coming
          </p>
        </div>
        <div className="flex flex-col items-center ">
          <div className="flex justify-center w-full md:w-2/3">
            <p className="w-4/5 text-center md:text-xl text-gray-500 mt-4">
            <span className='text-white font-bold'> dickbrAIn (noun) </span>– a man who thinks with his penis instead of his brain. In other words, his actions aren’t rational and decisions are totally driven by getting laid.
            </p>
          </div>
          <div className="flex justify-center w-full md:w-2/3">
            <p className="w-4/5 text-center md:text-xl text-gray-500 mt-4">
            Welcome to dickbrAIn; the next viral meme on BASE blockchain!
            </p>
          </div>
          <div className="flex justify-center w-full md:w-2/3">
            <p className="w-4/5 text-center md:text-xl text-gray-500 mt-4">
            We understand what people love about crypto: excitement, authenticity, and fun. That's what we're here to deliver, with some of the coolest marketing and art.
            </p>
          </div>
          <div className="flex justify-center w-full md:w-2/3">
            <p className="w-4/5 text-center md:text-xl text-gray-500 mt-4">
            Join us as we start a new chapter where dicks grow, communities bond, and we shape the future of memecoins together.
            
            </p>
          </div>
          
          <div className="flex justify-center w-full md:w-2/3">
            <p className="w-4/5 text-center md:text-xl text-white font-bold mt-4">
            For degens, by degens. Welcome aboard!
            </p>
          </div>
          <div className="text-center mt-4 bg-white/15 py-4 px-6 rounded-lg">
            <div>
              <p className="font-bold text-lg">Token Address:</p>
              <p className='text-sm'>
                <a className='underline' target='_blank' href="https://dexscreener.com/base/0x1b69f4a3af36ba841551ae20b9edf5e52827bea5">0xfe86b2ab783bab2543798eea1fd457364c81ef54</a>
                </p>
            </div>
            <div className='mt-4'>
              <p className="font-bold text-lg">Email:</p>
              <p>dickwifbrain@gmail.com</p>
            </div>
          </div>
          {/* <div className="flex justify-center w-full md:w-1/2">
            <div className="flex">
              <p className="w-3/5 text-center md:text-xl text-gray-500 mt-4 mr-2">
                At dickbrAIn, we're keeping the spirit of memes alive. Join us as we start a new chapter where memes grow, communities bond,
              </p>
              <p className="w-3/5 text-center md:text-xl text-gray-500 mt-4">
                and we shape the future of memecoins together. <br></br> For degens, by degens. Welcome aboard!
              </p>
            </div>
          </div> */}
        </div>
        {/* <button className='bg-basicViolet w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white'>Join Presale</button> */}
      </div>
    </div>
  );
};
