import React, {useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom';
import DickLogo from '../assets/dick_logo.png';
import ChatXWhite from '../assets/chatbot_x_white.svg';
import ChatXBlack from '../assets/chatbot_x_black.svg';
import { useAuth } from '../context/AuthContext'
import { postAddWallet, redirectToTwitter,getUserProfile } from '../services/userService.jsx';
import {
  FaTwitterSquare,
  FaWallet
} from 'react-icons/fa';

export const Navbar2 = () => {

    const { authToken, user, setUser, login, logout } = useAuth();
    const [walletAddress, setWalletAddress] = useState('');
    const [showInput, setShowInput] = useState(false);
    const [error, setError] = useState('');
    const[nav, setNav] = useState(false);
    const location = useLocation();

    const handleNav = () => {
        setNav(!nav)
      }
    const formatAddress = (address) => {
      return address ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';
    }

    const handleSubmit = async() => {
        const isSuccess = await postAddWallet(walletAddress);
        if(isSuccess === 'ok'){
          setUser(await getUserProfile());
        }else {
          setError(isSuccess);
        }
      }

      const resetWallet = () =>{
        setShowInput(false);
        setError('');
      }

      useEffect(() => {
        setNav(false);
      }, [location]); 
    //   window.navigation.addEventListener("navigate", (event) => {
    //     setNav(false);
    //   })
      const hideDropdownProfile = () => {
        const dropdown = document.getElementById('dropdownProfile')
        if(dropdown) {
            dropdown.classList.add('hidden');
        }
      }
      const toggleDropdownGames = () => {
        const dropdown = document.getElementById('dropdownGames');
        if(!dropdown) {
            return;
        }
        dropdown.classList.toggle('hidden');
        if (dropdown.classList.contains('hidden')) {
            ;
        } else {
            hideDropdownProfile();
        }
      }

      const hideDropdownGames = () => {
        const dropdown = document.getElementById('dropdownGames');
        if( dropdown) {
            dropdown.classList.add('hidden');
        }
      }
      const toggleDropdownProfile = () => {
        const dropdown = document.getElementById('dropdownProfile');
        if(!dropdown) {
            return;
        }
        dropdown.classList.toggle('hidden');
        if (dropdown.classList.contains('hidden')) {
            ;
        } else {
            hideDropdownGames();
        }
      }
     

    return(
        // fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 z-20
    <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
       <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={DickLogo} alt="Logo" className="h-8"/>
            <span className="self-center text-3xl font-bold whitespace-nowrap text-basicViolet">
                dickbr
                <span className='text-black'>AI</span>
                n
            </span>
            <div className='invisible '>
            <img src={ChatXWhite} className='w-0 invisible ' alt="" />
            <img src={ChatXBlack} className='w-0 invisible ' alt="" />
            </div>
        </Link>
        <button onClick={handleNav} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
        </button>
        {/*<div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 z-20' : 'ease-in-out duration-500 fixed left-[-100%] z-20'} id="navbar-dropdown">*/}
        <div className={nav ? 'w-[60%] h-full absolute md:relative left-0 top-10  md:top-0 md:block md:w-auto ease-in-out duration-500 z-20' : 'w-[60%] absolute md:relative left-[-100%] top-10 md:top-0 md:left-0  md:block md:w-auto ease-in-out duration-500 z-20'} id="navbar-dropdown">
          
        {/* <img src={DickLogo} alt="Logo" className="block md:hidden h-16"/> */}
          <ul className="flex flex-col h-full font-medium p-4 pt-12 md:p-0 mt-4  bg-white md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li className='py-2 md:py-0'>
                <Link to="/" aria-current="page" className='block py-2 px-3 text-gray-900   md:p-0 md:dark:bg-transparent'>Home</Link>
            </li>
            <li className='py-2 md:py-0'>
                <button id="dropdownNavbarGames" onClick={toggleDropdownGames} className="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-basicViolet 
                md:p-0 md:w-auto dark:text-white md:dark:hover:text-basicViolet dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent">
                    Games & More 
                <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                </svg></button>
                <div id="dropdownGames" className="z-10 block hidden md:absolute  font-normal bg-white divide-y divide-gray-100 rounded-lg md:shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                    <li>
                        <Link to="/dickslap" onClick={hideDropdownGames} aria-current="page" className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>Dickslap</Link>
                    </li>
                    {/* <li>
                        <Link to="/fappybird" onClick={hideDropdownGames} aria-current="page" className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>Fappy Bird</Link>
                    </li> */}
                    <li>
                        <Link to="/risingdick" onClick={hideDropdownGames} aria-current="page" className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>Dick Rising</Link>
                    </li>
                    <li>
                        <Link to="/dicksummer" onClick={hideDropdownGames} aria-current="page" className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>Dick Summer</Link>
                    </li>
                    </ul>
                </div>
            </li>
            {   user && <li className='py-2 md:py-0'>
                <button id="dropdownNavbarProfile" onClick={toggleDropdownProfile} className="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-basicViolet
                md:p-0 md:w-auto dark:text-white md:dark:hover:text-basicViolet dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent">
                    {/* <FaTwitterSquare size={20}  className='mr-2'/>  */}
                      {user.twitterHandle}
                <svg className={`w-2.5 h-2.5 ms-2.5 ${!user.walletAddress ? 'bobbing-animation' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                </svg>
                </button>
                <div id="dropdownProfile" className="z-10 block hidden md:absolute font-normal bg-white divide-y divide-gray-100 rounded-lg md:shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                    
                    {user?.walletAddress && <li>
                        {/* <Link to="/dickslap" aria-current="page" className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>Dickslap</Link> */}
                        
                        <div className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                            <FaWallet size={10}  className='mr-2'/>  
                            {formatAddress(user?.walletAddress) } 
                        </div> 
                        </li>
                    }
                    {(showInput || !user?.walletAddress)  && <li>
                        <input
                            type="text"
                            id="walletAddress"
                            value={walletAddress}
                            onChange={(e) => setWalletAddress(e.target.value)}
                            placeholder="ETH address"
                            className="block px-4 py-2 dark:hover:bg-gray-600 border-b dark:hover:text-white w-full"
                        />
                        </li>
                    }
                    <li className='py-2 md:py-0'>
                        <button onClick={(!user?.walletAddress || showInput) ? handleSubmit : () => setShowInput(true)} className='w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer'>
                            {(!user?.walletAddress || showInput) ? 'Submit' : 'Change wallet' }
                        </button>
                        {showInput  && <button onClick={resetWallet} className='w-full px-4 border-t py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer'>
                            Cancel
                            </button>
                        }
                        {error &&<p className='block px-4 py-2 text-red-600 border-t'>{error}</p> }
                    </li>
                    </ul>
                    <div className="py-1">
                        <a onClick={logout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer">Sign out</a>
                    </div>
                </div>
              </li>
            }
            { !user && <li className='py-2 md:py-0'>            
                <a onClick={redirectToTwitter} target='_blank' className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-basicViolet md:p-0
                 dark:text-white md:dark:hover:text-basicViolet dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent underline cursor-pointer">Connect Twitter</a>
                </li>
            }
            <li className='py-2 md:py-0'>            
                <a href='https://app.uniswap.org/swap?outputCurrency=0xfe86b2ab783bab2543798eea1fd457364c81ef54' target='_blank' className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-basicViolet md:p-0
                 dark:text-white md:dark:hover:text-basicViolet dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent underline">Buy $DICK</a>
            </li>
        </ul>
    </div>
  </div>
</nav>


    )
}