import React, { useState, useEffect } from 'react';
import { getGameLeaderboard, getCurrentSeason } from '../services/gameService';

export const Leaderboard = ({ gameId }) => {
    const [sortedData, setSortedData] = useState([]);
    const [seasons, setSeasons] = useState(0);
    const [showlb, setShowlb] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState('1');  // Default to the first season

    const handleShowlb = () => {
        setShowlb(!showlb);
    };

    const handleSeasonChange = async (event) => {
        setSelectedSeason(event.target.value);        
        setSortedData(await getGameLeaderboard(gameId, event.target.value));
    };

    function selectLastOption() {
        try{
            var selectElement = document.getElementById('season-select');
            var options = selectElement.options;
            var lastOptionValue = options[options.length - 1].value;
            selectElement.value = lastOptionValue;
        }
        catch(e) {

        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await getGameLeaderboard(gameId);
            setSortedData(data);
            const currentSeason = await getCurrentSeason(gameId);
            setSeasons(currentSeason.season ?? 1);
            setSelectedSeason(1);      
            setTimeout(() => {
                selectLastOption();
            }, 2000);
        };
        fetchData();
    }, [gameId]);  // Include gameId in the dependency array if it can change


    return (
        <div className='max-w-[1200px] w-full mx-auto p-4 text-white'>
            <div className='m-4 text-center'>
                {gameId == 1 && <p className='text-basicViolet text-2xl font-bold'>Dickslap</p> }
                {gameId == 2 && <p className='text-basicViolet text-2xl font-bold'>Fappy bird</p> }
                <p>Weekly leaderboard - restarts every Monday</p>
                <p className='mb-2 font-semibold'>20 places paid (48000 $DICK)
                    
                </p>
                <p>
                    <button className='bg-basicViolet m-2 p-2 pr-4 rounded-lg hover:bg-hoverViolet' onClick={handleShowlb}>
                        {!showlb ? 'Show' : 'Hide'} leaderboard
                    </button>
                </p>
            </div>
            {showlb &&             
                <div>                
                    <div className='m-4'>
                        <label htmlFor="season-select" className="mr-2 font-semibold">Season:</label>
                        <select id="season-select" value={selectedSeason} onChange={handleSeasonChange} className="bg-gray-800 text-white p-2 rounded">
                            {[...Array(seasons).keys()].map((i) => (
                                <option key={i + 1} value={i + 1}>
                                    {i + 1 === seasons ? 'Current' : `Season ${i + 1}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <table className="w-full text-white border-separate" style={{ borderSpacing: 0 }}>
                        <thead>
                            <tr className="bg-gray-800">
                                <th className="md:px-4 px-1 py-2 border border-gray-400">Rank</th>
                                <th className="md:px-4 px-1 py-2 border border-gray-400">Username</th>
                                <th className="md:px-4 px-1 py-2 border border-gray-400">Score</th>
                                <th className="md:px-4 px-1 py-2 border border-gray-400">Expected $DICK Airdrop</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((item, index) => (
                                <tr key={index} className="bg-gray-700 hover:bg-gray-400  text-center">
                                    <td className="md:px-4 px-1 py-1 border border-gray-400">{item.rank}</td>
                                    <td className="md:px-4 px-1 py-1 border border-gray-400">{item.username}</td>
                                    <td className="md:px-4 px-1 py-1 border border-gray-400">{item.totalScore}</td>
                                    <td className="md:px-4 px-1 py-1 border border-gray-400">{item.expectedValue}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                </div>
            }
        </div>
    );
};
