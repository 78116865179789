import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import TeamImage from '../assets/dick_team.png'
import TomImage from '../assets/tom_logo.png'
import DanieleImage from '../assets/daniele_logo.png'
import MaxImage from '../assets/max_logo.png'
import HesterImage from '../assets/hester_logo.png'

export const Team = () => {

    const teamMembers = [
        { id: 1, role: 'Project manager', image: TomImage },
        { id: 2, role: 'Developer'},
        { id: 3, role: 'Marketing expert', image: MaxImage },
        { id: 4, role: 'Tokenomics expert'},
        { id: 5, role: 'Designer', image: HesterImage },
        { id: 6, role: 'Collab manager' },
        { id: 7, role: 'Community manager' , image: DanieleImage },
        // { id: 8, role: 'Backend developer' },
        // { id: 9, role: 'Collab manager' , image: MaxImage },
        // { id: 10, role: 'UX/UI Designer' },
      ];

  return (
    <div id="team" className='bg-gray-200'>
        
    <div className="max-w-[1200px] p-4 m-auto">
      <h1 className="text-black text-xl font-bold text-center mb-4 lg:mb-10 md:text-5xl sm:text-4xl">Bunch of dicks and a surprise</h1>
      <Swiper
        modules={[Autoplay, EffectCoverflow, Pagination]}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={3}
        coverflowEffect={{
          "rotate": 80,
          "stretch": 0,
          "depth": 70,
          "modifier": 1,
          "slideShadows": false
        }}
        pagination={false}        
        autoplay={{
            "delay": 2000,
            "disableOnInteraction": false
          }}
        className="mySwiper mb-8 lg:mb-12"
      >
        {teamMembers.map(member => (
          <SwiperSlide key={member.id} className="flex flex-col items-center">
            <div className="relative lg:ml-10 md:ml-6">
              { <img src={member.image ? member.image : TeamImage} alt="Team Member" className="mb-2 w-24 h-24 md:w-48 md:h-48 lg:w-56 lg:h-56 object-cover rounded-full"/> }
              {/* {!member.image && 
              <img src={member.image} alt="Small Image" className="absolute w-12 h-12 md:w-24 md:h-24 lg:w-24 lg:h-36 object-cover rounded-full top-6 left-10"/> } */}

              <div className="absolute w-full text-center bottom-0 top-0 flex items-center justify-start text-basicViolet">

                  <p className='bg-white mt-14 md:mt-32 p-1 rounded-xl lg:text-xl md:text-sm text-xs'>
                      {member.role}
                  </p>
              </div>
            </div>
               
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </div>
  )
}
