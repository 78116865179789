const API_URL = 'https://dickwifbrain.xyz/dickapi';

export const postAddWallet = async (walletAddress) => {
    const url = `${API_URL}/dick/add-wallet/${walletAddress}`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        });

        if (!response.ok) {
            const errorData = await response.text();
            return errorData;
        }
        return 'ok';

    } catch (error) {
        return 'error';
    }
}

  
export const getUserProfile = async() =>{
    const url = `${API_URL}/dick/user-profile`;

    if(!localStorage.getItem('authToken'))
      return;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
  
      if (!response.ok) {
        console.error('Network response was not ok.');
        return;
      }
  
      const data = await response.json();
      
      return data;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      return;
    }
  }

  
  export const redirectToTwitter = () => {
    //add for prod
    //window.location.href=API_URL + `/auth/generate-twitter-url?isProd=${window.location.host === 'dickbrain.xyz'}`;//oauth2
    window.location.href=API_URL + `/login-twitter?isProd=${window.location.host === 'dickbrain.xyz'}`;
  }