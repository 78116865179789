const API_URL = 'https://dickwifbrain.xyz/dickapi';

export const getGameLeaderboard = async (gameId, seasonId = null) => {
    const url = `${API_URL}/game/top-scores?gameId=${gameId}` + (seasonId ? `&season=${seasonId}` : '');
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            console.error('Network response was not ok.');
            return [];
        }

        return await response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return []; // Ensure state is cleared on error
    }
}

export const getCurrentSeason = async (gameId) => {
    const url = `${API_URL}/game/current-season?gameId=${gameId}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            console.error('Network response was not ok.');
            return {};
        }

        return await response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return {}; // Ensure state is cleared on error
    }
}

export const getRisingDick = async (season) => {
    var url = `${API_URL}/dick/rising-leaderboard`;
    if(season) {
        url += `?season=${season}`
    }
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            console.error('Network response was not ok.');
            return {};
        }

        return await response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return {}; // Ensure state is cleared on error
    }
}

export const getCm = async () => {
    var url = `${API_URL}/dick/total-centimeters`;
    
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            console.error('Network response was not ok.');
            return {};
        }

        return await response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return {}; // Ensure state is cleared on error
    }
}
