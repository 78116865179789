// useEventEmitter.js
import { useEffect } from 'react';
import EventEmitter from 'events';

const emitter = new EventEmitter();

export const useEventEmitter = (eventName, handler) => {
    useEffect(() => {
        emitter.on(eventName, handler);

        return () => {
            emitter.removeListener(eventName, handler);
        };
    }, [eventName, handler]);
};

export const triggerEvent = (eventName, data) => {
    emitter.emit(eventName, data);
};
