import React,{useEffect,useRef} from 'react';
import { WhatIs } from './WhatIs'
import { Team } from './Team'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'

export const DickInfo = () => {
  const { authToken, login, logout } = useAuth();
  const executingRef = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const bearer = query.get('bearer'); 

  useEffect(() => {
    
    if(bearer){
      login(bearer);
    }
    console.log("redirect");
    navigate(location.pathname, { replace: true }); 
    
  }, [bearer]);


  return (
    <div>
        <WhatIs />
        <Team />
    </div>
  );
};