import { useAuth } from '../context/AuthContext'
import { Leaderboard } from "./Leaderboard";
import { redirectToTwitter } from '../services/userService'

export const FappyBirdGame = () => {
  const {  user } = useAuth();
    return (
      <div className="max-w-[1200px] w-full mx-auto min-h-screen p-4">
        
      <Leaderboard  gameId={2}/>
      {user ? 
        <iframe
          src={`/FappyBirdGame/index1.html?token=${localStorage.getItem('authToken')}&v=1.01`}
          title="Fappy Bird Game"
          width="100%" 
          height="800px"
          style={{
            maxHeight: '87vh'
          }}
          allowFullScreen
        ></iframe>
        :
        <>
         <p className="text-white text-center">You need to connect your twitter account in order to play the game
          <a onClick={redirectToTwitter} target='_blank'>
            <button className="bg-basicViolet m-2 p-2 pr-4 rounded-lg hover:bg-hoverViolet underline cursor-pointer">
              Connect Twitter
            </button>
          </a>
          </p>
        </>
      }
      </div>
    );
  }
  