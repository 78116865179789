import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserProfile } from '../services/userService.jsx';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null); 
  const [user, setUser] = useState(null);
 
  const profile = (profile) => {
    setUser(profile);
  }

  const  login = async (token) => {
    setAuthToken(token); // Function to update the token
    localStorage.setItem('authToken', token);
    profile(await getUserProfile());
  };

  const logout = () => {
    localStorage.removeItem('authToken'); // Remove token from localStorage    
    setAuthToken(null); // Function to clear the token
    setUser(null); // Clear user information
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      login(token);
    }
    console.log("auth effect");
    // Optional: Cleanup on component unmount
    // return () => {
    //   localStorage.removeItem('authToken');
    // };
  }, []);

  return (
    <AuthContext.Provider value={{ authToken, user, setUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
