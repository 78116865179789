import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {getRisingDick, getCm} from '../services/gameService'
import { getCurrentSeason } from '../services/gameService';

 export const RisingDick = () =>  {
    const[rowData, setRowData] = useState(false)
    const [seasons, setSeasons] = useState(0);
    const [selectedSeason, setSelectedSeason] = useState(0); 

    const [columnDefs] = useState([
        { headerName: "Rank", field: "rank" },
        { headerName: "User", field: "username" },
        { 
            headerName: "Dick size (cm)", 
            field: "totalScore", 
            valueFormatter: params => params.value.toFixed(0),
            cellStyle: { textAlign: 'center', alignItems: 'center', display: 'flex'} 
        },
            
        { 
            headerName: "Expected $DICK Airdrop", 
            field: "expectedValue", 
            valueFormatter: params => Math.round(params.value).toLocaleString(),
            cellStyle: { textAlign: 'center', alignItems: 'center', display: 'flex' }
        },        
        { headerName: "Wallet status", field: "walletStatus" },
    ]);

    const handleSeasonChange = async (event) => {
        var season = event.target.value;
        setSelectedSeason(season);       
        setRowData(await getRisingDick(season)); 
    };
    useEffect(() => {
        const fetchData = async() => {
            setRowData(await getRisingDick());
            const currentSeason = await getCurrentSeason(2);
            setSeasons(currentSeason.season ?? 1);
            setSelectedSeason(currentSeason.season);  
            await getCm();
        }
        fetchData();
    }, [])
    
    const gridRef = useRef(null);

    const onFilterTextBoxChanged = () => {
        gridRef.current.api.setQuickFilter(document.getElementById('filter-text-box').value);
    };

    return (
        
        <div className='max-w-[1200px] w-full mx-auto p-4 text-white'>
            <div className='m-4 text-center'>
                <p className='text-basicViolet text-2xl font-bold'>Dick Rising (Dick Summer phase)</p>
                <p>The Ultimate Social Game (Or as someone would call -&gt; Dick Measuring Contest).</p>
                
                <p className='mt-4 font-semibold'>200000 $DICK distributed to TOP 200 users based on ranking
                </p>
                <p className='mb-2 font-semibold'>300000 $DICK distributed to TOP 100 users based on percentage of points of the TOP 100
                </p>
                <p className='font-bold mt-4'>Engagement on TWEETs</p>
                <p>Reply - 30 points</p>
                <p>Retweet - 20 points</p>
                <p>Like - 10 point</p>
                <p className='font-bold mt-4'>Engagement on REPLIES</p>
                <p>Reply - 15 points</p>
                <p>Like - 5 points</p>
                <p className='mt-4'>Weekly leaderboard - restarts every Monday</p>
                <p className='italic'>Most points are updated twice daily, with additional updates occurring on Thursdays and Sundays.</p>
            </div>
            {/* <div className="flex items-center space-x-4">   */}
            <hr className='mb-4' />
            <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 md:mb-0 mb-4 items-center"> {/* Responsive Flex Container */}
                <div className='m-4'>
                    <label htmlFor="season-select" className="mr-2 font-semibold">Season:</label>
                    <select id="season-select" value={selectedSeason} onChange={handleSeasonChange} className="bg-gray-800 text-white p-2 rounded">                    
                        <option key={0} value={0}>
                            Preseason
                        </option>
                        {[...Array(seasons).keys()].map((i) => (
                            <option key={i + 1} value={i + 1}>
                                {i + 1 === seasons ? 'Current' : `Season ${i + 1}`}
                            </option>
                        ))}
                    </select>
                </div>

                <input type="text" id="filter-text-box" className='text-black  className="form-input px-4 py-2 border border-gray-300 focus:outline-none  rounded-xl' onInput={onFilterTextBoxChanged} placeholder="Search..." />
                
                
            </div>

            <div className="ag-theme-alpine w-full"> {/* Removed fixed height and width, applied full width */}
                <AgGridReact className='text-black'
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={rowData}   
                    pagination={true}  // Enable pagination
                    paginationPageSize={50}  // Set number of rows per page to 20                   
                    domLayout='autoHeight'
                    suppressMovableColumns={true}> {/* This property tells AG-Grid to use automatic height */}
                </AgGridReact>
            </div>
        </div>

    );
}
