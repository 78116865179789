import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar2 } from './components/Navbar2';
import { DickInfo } from './components/DickInfo';
import { Footer } from './components/Footer'
import { Pumping } from './components/Pumping';
import { DickSummer } from './components/DickSummer';
import { AuthProvider } from './context/AuthContext';
import { SbfGame } from "./components/SbfGame";
import { RisingDick } from "./components/RisingDick";
import { FappyBirdGame } from "./components/FappyBirdGame";

function App() {
  
  
  const hideDropdowns = () => {
    console.log("parent");
    var dropdown = document.getElementById('dropdownGames');
    if(dropdown) {
      dropdown.classList.add('hidden');
    }
    dropdown = document.getElementById('dropdownProfile');
    if(dropdown) {
      dropdown.classList.add('hidden');
    }
  }
  return (
    <BrowserRouter>
      <AuthProvider>
        <Navbar2 />
        <div  onClick={hideDropdowns}>
          <Routes >
            <Route onClick={hideDropdowns} path="/" element={<DickInfo />} />
            {/* <Route path="/moonmeter" element={<Pumping />} /> */}
            <Route path="/dicksummer" element={<DickSummer />} />
            <Route path="/risingdick" element={<RisingDick />} />
            <Route path="/dickslap" element={<SbfGame />} />
            <Route path="/fappybird" element={<FappyBirdGame />} />
          </Routes>
          <Footer />
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
