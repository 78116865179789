import React, {useEffect,  useState} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import {TailSpin} from 'react-loader-spinner';
import { useAuth } from '../context/AuthContext'
import { Leaderboard } from "./Leaderboard";
import { useLocation } from 'react-router-dom';
import { getGameLeaderboard } from '../services/gameService'
import { redirectToTwitter } from '../services/userService'

export const SbfGame= () => {
  const location = useLocation();
  const { authToken, user, login, logout } = useAuth();
  //const jwtToken = ""; // Adjust this, front end needs send JWT to game
  const [isGameReady, setIsGameReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { unityProvider, addEventListener, removeEventListener, sendMessage, unload } = useUnityContext({
    loaderUrl: "Game/Final1.loader.js",
    dataUrl: "Game/Final1.data",
    frameworkUrl: "Game/Final1.framework.js",
    codeUrl: "Game/Final1.wasm",
  });


  const reloadLeaderboard = async () => {
    
  };
  useEffect(() => {
    const handleGameReady = () => {
      console.log("Unity is now ready!");
      setIsGameReady(true);  // Update state to reflect Unity is ready
      
      // Unity
      sendMessage("WebsiteToGame", "SendJWTToken", authToken);
      setIsLoading(false);
    };

    addEventListener("gameReady", handleGameReady);
    addEventListener("onResultSubmitted", reloadLeaderboard);
    reloadLeaderboard();
    // Cleanup unmount
    return () => {
      removeEventListener("gameReady", handleGameReady);
    };
  }, [addEventListener, removeEventListener, sendMessage, authToken]);

  return (
    <div className="max-w-[1200px] w-full mx-auto min-h-screen p-4">
      
      <Leaderboard  gameId={1}/>
      {user ? <>

        { isLoading && <p className="text-white"> loading... </p>}
        {/* <div className="bg-gray-600" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
              </div> */}
        <Unity className="m-auto max-w-[1200px] w-full" unityProvider={unityProvider} />
      
      </> : 
      <>
        <p className="text-white text-center">You need to connect your twitter account in order to play the game
        <a onClick={redirectToTwitter} target='_blank'>
          <button className="bg-basicViolet m-2 p-2 pr-4 rounded-lg hover:bg-hoverViolet underline cursor-pointer">
            Connect Twitter
          </button>
        </a>
        </p>
      </>

      }
    </div>
  );
}

export default SbfGame;